@use 'sass:color';
@use 'src/styles/config';

.container {
  @include config.brand-text-lg-semibold;
  border: none;
  padding: 6px 26px;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container:disabled {
  opacity: 0.6;
}

.container-primary {
  background: config.$brand-color-secondary;
  color: #fff;
}

.container-primary:focus {
  outline: config.$mixin-focus-element;
}

.container-secondary {
  background: #fff;
  color: config.$brand-color-secondary;
}

.container-primary:hover {
  background: color.scale(config.$brand-color-secondary, $blackness: 10%);
  transition: 0.1s;
}

.container-secondary:hover {
  background: #e5e5e5;
  transition: 0.3s;
}

.button-text {
  margin-left: 5px;
}

@media (max-width: config.$breakpoint-md) {
  .container {
    font-size: config.$brand-fs-mobile-text-default;
  }
}
