.appForceUpdateModal {
  text-align: center;
  padding: 0 25px 0 25px;
}

.appForceUpdateModal h2 {
  margin-top: 20px;
  font-size: 1.75rem;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.appForceUpdateModal p {
  width: 70%;
  margin: 20px auto 0 auto;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.update-button-style {
  margin-top: 3em;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 991px) {
  .appForceUpdateModal p {
    width: 100%;
  }
}
