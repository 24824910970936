@use "src/styles/config";

.container {
    padding: 41px 12%;
    margin: 0 auto;
    background: config.$brand-color-background-primary-light;
    border-top: 5px solid config.$brand-color-alert;
}
  
.container h2 {
    @include config.brand-header-lg;
    margin: 0 0 20px;
    text-align: center;
}
  
.container p {
    @include config.brand-subtext-default-regular;
    color: rgba(96, 97, 99, 0.6);
    text-align: center;
    margin: 0;
}
