@use "./colors";
@use "../breakpoints";

/* Font Sizes - Desktop */
$fs-header-xl: 2.125rem;
$fs-header-lg: 1.625rem;
$fs-header-default: 1.25rem;
$fs-header-sm: 1.125rem;
// ---
$fs-text-lg: 1.125rem;
$fs-text-default: 1rem;
$fs-text-sm: 1rem;
// ---
$fs-subtext-default: 0.875rem;

/* Font Sizes - Mobile */
$fs-mobile-header-xl: 1.375rem;
$fs-mobile-header-lg: 1.125rem;
$fs-mobile-header-default: 1rem;
$fs-mobile-header-sm: 0.75rem;
// ---
$fs-mobile-text-lg: 1.125rem;
$fs-mobile-text-default: 1rem;
$fs-mobile-text-sm: 0.875rem;
// ---
$fs-mobile-subtext-default: 0.688rem;

/* Font weight */
$fw-semibold: 600;
$fw-bold: 700;
$fw-extrabold: 800;

/* Font family */
$ff-name: 'ProximaNova';
$ff-default: $ff-name, sans-serif;

// TODO: revisit font size desktop to mobile mapping
/* Font Presets */
@mixin header-xl {
  color: colors.$foreground-primary;
  font-weight: $fw-extrabold;
  font-size: $fs-mobile-header-xl;
  line-height: 1.625rem;
  letter-spacing: -0.2px;

  @media (min-width: breakpoints.$md) {
    font-size: $fs-header-xl;
    line-height: 2.25rem;
    letter-spacing: normal;
  }
}

@mixin header-lg {
  color: colors.$foreground-primary;
  font-weight: $fw-extrabold;
  font-size: $fs-mobile-header-lg;
  line-height: 1.5rem;
  letter-spacing: -0.2px;

  @media (min-width: breakpoints.$md) {
    font-size: $fs-header-lg;
    line-height: 2.25rem;
  }
}

@mixin header-default {
  color: colors.$foreground-primary;
  font-weight: $fw-bold;
  font-size: $fs-mobile-header-default;
  line-height: 1.375rem;
  letter-spacing: -0.2px;

  @media (min-width: breakpoints.$md) {
    font-size: $fs-header-default;
    line-height: 1.5rem;
  }
}

@mixin header-sm {
  color: colors.$foreground-primary;
  font-weight: $fw-bold;
  font-size: $fs-header-sm;
  line-height: 0.938rem;
  letter-spacing: normal;

  @media (min-width: breakpoints.$md) {
    font-size: $fs-header-sm;
    line-height: 1.5rem;
  }
}

@mixin text-lg-regular {
  color: colors.$foreground-primary;
  font-size: $fs-mobile-text-lg;
  font-weight: 500;
  line-height: 1.75rem;
  letter-spacing: -0.2px;

  @media (min-width: breakpoints.$md) {
    font-size: $fs-text-lg;
  }
}

@mixin text-lg-semibold {
  @include text-lg-regular;
  font-weight: $fw-semibold;
}

@mixin text-default-regular {
  color: colors.$foreground-primary;
  font-size: $fs-mobile-text-default;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: normal;

  @media (min-width: breakpoints.$md) {
    font-size: $fs-text-default;
    line-height: 1.5rem;
  }
}

@mixin text-default-semibold {
  @include text-default-regular;
  font-weight: $fw-semibold;
}

@mixin text-sm-regular {
  color: colors.$foreground-primary;
  font-size: $fs-mobile-text-sm;
  line-height: 1.125rem;
  font-weight: 500;
  letter-spacing: normal;

  @media (min-width: breakpoints.$md) {
    font-size: $fs-text-sm;
    line-height: 1.375rem;
  }
}

@mixin text-sm-semibold {
  @include text-sm-regular;
  font-weight: $fw-semibold;
}

@mixin subtext-default-regular {
  color: colors.$foreground-primary;
  font-size: $fs-subtext-default;
  line-height: 0.875rem;
  font-weight: 500;
  letter-spacing: normal;

  @media (min-width: breakpoints.$md) {
    font-size: $fs-subtext-default;
    line-height: 1.25rem;
  }
}

@mixin subtext-default-semibold {
  @include subtext-default-regular;
  font-weight: $fw-semibold;
}

:export {
  fontWeightSemibold: $fw-semibold;
  fontWeightBold: $fw-bold;
  fontSizeHeaderDefault: $fs-header-default;
  fontSizeSubtextDefault: $fs-subtext-default;
  fontSizeTextLarge: $fs-text-lg;
  fontSizeTextDefault: $fs-text-default;
}
