@use "src/styles/config";

.container {
  padding: 0 15px;
  background-color: rgba(255, 255, 255, 1);
  color: config.$brand-color-secondary;
  border-radius: 20px;
  font-weight: config.$brand-fw-semibold;
  text-decoration: underline;
  border: none;
  transition: all 0.3s ease;
}

.container:hover {
  text-decoration: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.04);
}
